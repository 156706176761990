<template>
  <add-to-card-button
    :selected-options="selectedOptions"
    :product="product"
    :count="props.count"

    @errorList="(errorList)=>emits('errorList')"
  >
    <template #cartTemplateBtn="{hasErrors, isLoading , addProductToCard, countAddedToCart, changeProductCount, isAddToCart, }">
      <div class="template-cart-btn mt-3">
        <client-only>
          <template #fallback>
            <Button
              class="w-full"
              :ui="{padding:'px-7 py-3'}"
              color="green-light"
              size="sm"
              @click="addProductToCard({
                isClear:true
              })"
            >
              в корзину
            </Button>
          </template>
          <div
            v-if="countAddedToCart > 0 && !showPrice"
            class="count-info flex rounded-2.5 bg-#eef7e7 justify-between items-center gap-3 bg-firmennyy/80"
          >
            <button
              class="btn-change size-9 flex items-center justify-center bg-firmennyy disabled:bg-transparent leading-0.8 rounded-2.5 text-white text-7"
              :disabled="isLoading"
              @click="changeProductCount(countAddedToCart - 1)"
            >
              <span class="h-6.5">
                -
              </span>
            </button>

            <div class="counter w-8 text-4.5 font-500 text-center text-white">{{ countAddedToCart }}</div>
            <button
              class="btn-change size-9 flex items-center justify-center bg-firmennyy disabled:bg-transparent leading-0.8 rounded-2.5 text-white text-7"
              :disabled="isLoading"
              @click="changeProductCount(countAddedToCart + 1)"
            >
              +
            </button>
          </div>

          <Button
            v-if="!isAddToCart"
            :disabled="hasErrors"
            class="w-full"
            :ui="{padding:'px-7 py-3'}"
            :color="showPrice ? 'green' : 'green-light'"
            size="sm"
            @click="addProductToCard({
              isClear:true
            })"
          >
            {{ showPrice ? `Купить за ${product.price + totalIngredientsPrice} ₽` : 'в корзину' }}
          </Button>
          <Button
            v-if="countAddedToCart > 0 && showPrice"
            :disabled="hasErrors"
            class="w-full"
            :ui="{padding:'px-7 py-3'}"
            color="green"
            size="sm"
            @click="changeProductCount(countAddedToCart + 1)"
          >
            {{ showPrice ? `Купить за ${currentPrice ? currentPrice : product.price + totalIngredientsPrice} ₽` : 'в корзину' }}
          </Button>
        </client-only>
      </div>
    </template>
  </add-to-card-button>
</template>

<script setup lang="ts">

import addToCardButton from '~/components/widgets/add-to-cart/AddToCardLayout.vue';
import type { ProductAdditionalIngredientsGroupsType, ProductType } from '~/composables/product/types/UseProductType';
import { useCart } from '~/composables/cart/useCart';

const props = defineProps<{
	product: ProductType;
	selectedOptions?: ProductAdditionalIngredientsGroupsType[];
  count: number;
  showPrice?: boolean;
  currentPrice?: number;
}>();

const totalIngredientsPrice = computed(() => {
  if (!props.selectedOptions) {
    return 0;
  }
  return props.selectedOptions?.reduce((total, group) => {
    const groupTotal = group.list.reduce((sum, item) => {
      return sum + item.price;
    }, 0);
    return total + groupTotal;
  }, 0);
});

const emits = defineEmits<{
	errorList: []
}>();
</script>
