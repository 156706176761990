<template>
  <div
    class="wish-btn"
    @click.stop="handlerWishAdd"
  >
    <slot :is-wish="isWish">
      <client-only>
        <template #fallback>
          <img
            src="/imgs/wish/heart.svg"
            alt="wish"
          >
        </template>
        <img
          v-if="!isWish"
          src="/imgs/wish/heart.svg"
          alt="wish"
        >
        <img
          v-else
          src="/imgs/wish/heart-fill.svg"
          alt="wish"
        >
      </client-only>
    </slot>
  </div>
</template>

<script setup lang="ts">

import { useWish } from '~/composables/wish/useWish';
import { Wish } from '~/utils/wish/Wish';
import type { RestaurantType } from '~/composables/restaurant/types/RestaurantType';
import type { ProductType } from '~/composables/product/types/UseProductType';

const props = defineProps<{
	id: number;
	wishType: 'products'|'restaurants'
}>();
const {
  wishStoreList,
  wishAddRestaurant,
  wishGetRestaurantList,
  wishGetProductList,
  wishRemoveRestaurant,
  wishRemoveProduct,
  wishAddProduct
} = useWish();

let wish:Wish<any> | undefined;

switch (props.wishType) {
  case 'restaurants':
    wish = new Wish<RestaurantType>(props.id, wishAddRestaurant, wishRemoveRestaurant, wishGetRestaurantList);
    break;

  case 'products':
    wish = new Wish<ProductType>(props.id, wishAddProduct, wishRemoveProduct, wishGetProductList);
    break;

  default:
    console.log('Unknown wish type:', props.wishType);
    break;
}

const isWish = computed(() => {
  return wishStoreList.value[props.wishType].some((t) => t.id === props.id);
});

const handlerWishAdd = async () => {
  if (wish) {
    if (isWish.value) {
      await wish?.removeToWish();
    } else {
      await wish?.addToWish();
    }
    await wish.getWishList();
  }

  return;
};

</script>

<style scoped lang="scss">
.wish-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	img {
		width: 27px;
		height: 27px;
		transition: all 0.3s;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}
</style>
