type AddToWish = (options:{id:number}) => Promise<boolean>;
type RemoveToWish = (options:{id:number}) => Promise<boolean>;
type GetWishList<T> = () => Promise<T[]>;
type IsInWish = () => boolean;

export class Wish<T> {
  private readonly addToWishFnc: AddToWish;
  private readonly removeToWishFnc: RemoveToWish;
  private readonly getWishListFnc: GetWishList<T>;
  private readonly id:number;

  constructor (id: number, addToWishFnc: AddToWish, removeToWishFnc: RemoveToWish, getWishListFnc:GetWishList<T>) {
    this.addToWishFnc = addToWishFnc;
    this.removeToWishFnc = removeToWishFnc;
    this.getWishListFnc = getWishListFnc;
    this.id = id;
  }

  async addToWish ():Promise<boolean> {
	  return await this.addToWishFnc({ id: this.id }).catch((err) => {
		  console.log(err.data.message);
		  return false;
	  });
  }

  async removeToWish ():Promise<boolean> {
    return await this.removeToWishFnc({ id: this.id }).catch((err) => {
      console.log(err.data.message);
      return false;
    });
  }

  async getWishList ():Promise<T[]> {
	  return await this.getWishListFnc().catch((err:any) => {
	    console.log(err.data.message);
	    return [] as T[];
    });
  }
}
