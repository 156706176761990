<template>
  <div>
    <slot
      :add-product-to-card="addProductToCard"
      :change-product-count="changeProductCount"
      :count-added-to-cart="productCount"
      :is-loading="isLoading"
      :is-add-to-cart="productCount > 0"
      :has-errors="hasErrors"
      name="cartTemplateBtn"
    >
      <div></div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { ref } from '#imports';
import type { ProductAdditionalIngredientsGroupsType, ProductType } from '~/composables/product/types/UseProductType';
import { useCart } from '~/composables/cart/useCart';
import type { CartProductItemType } from '~/composables/cart/types/CartType';
import toast from '~/plugins/toast';

export interface IAddToCard {
	isClear: boolean;
}

defineSlots<{
	cartTemplateBtn:(props: {
		addProductToCard: (params: IAddToCard) => void;
		changeProductCount: (newCount: number) => void;
		countAddedToCart: number;
		isLoading: boolean;
		isAddToCart: boolean;
		hasErrors:boolean;
	}) => any;
}>();

const props = defineProps<{
	product: ProductType;
	selectedOptions?: ProductAdditionalIngredientsGroupsType[];
  count: number;
}>();

const emits = defineEmits<{
	errorList: []
}>();
const { $toast } = useNuxtApp();

const { cartList, changeCart } = useCart();
const isLoading = ref<boolean>(false);
const currentProductFromCart = ref<CartProductItemType | null>(updateCurrentProd());
const hasErrors = ref<boolean>(false);
const productCount = ref<number>(props.count);

const additionalIngredients = computed(() => {
  return props.product?.additional_ingredient_groups || [];
});

function updateCurrentProd () {
  for (const cartItem of cartList.value) {
    const product = cartItem.cart_products.find((prod) => prod.product_id === props.product.id);
    if (product) {
      return { ...product };
    }
  }
  return null;
}

async function addProductToCard (params: IAddToCard = { isClear: false }) {
  console.log('add');
  const { isClear } = params;

  if (isLoading.value || checkErrors()) {
    return;
  }

  productCount.value = 1;
  isLoading.value = true;

  if (isClear && productCount.value <= 0) {
    await removeProductCount();

    productCount.value = 0;
    isLoading.value = false;

    return;
  }

  let idForm = 0;
  const formData = new FormData();

  formData.append('product_id', String(props.product.id));
  formData.append('count', String(1));

  if (props.selectedOptions && props.selectedOptions.length > 0) {
    props.selectedOptions.forEach((option) => {
      option.list.forEach((optionItem) => {
        formData.append(`additional_ingredient_ids[${idForm}]`, String(optionItem.id));
        idForm++;
      });
    });
  }

  await changeCart(formData as any).catch((err) => {
    $toast.error(err.data.message || 'Не удалось добавитьв корзину');
	  console.log('error', err.response._data.message);
  });

  isLoading.value = false;
}

async function changeProductCount (newCount: number) {
  console.log('change');
  if (isLoading.value || checkErrors()) {
    return;
  }

  // if (newCount <= 0) {
  //   productCount.value = 0;
  //   await removeProductCount();
  //   return;
  // }

  const formData = new FormData();

  formData.append('product_id', String(props.product.id));
  formData.append('count', String(newCount));

  let idForm = 0;

  if (props.selectedOptions && props.selectedOptions.length > 0) {
    props.selectedOptions.forEach((option) => {
      option.list.forEach((optionItem) => {
        formData.append(`additional_ingredient_ids[${idForm}]`, String(optionItem.id));
        idForm++;
      });
    });
  }

  if (currentProductFromCart.value) {
    console.log(currentProductFromCart.value);
    currentProductFromCart.value.additional_ingredients.forEach((option) => {
      formData.append(`additional_ingredient_ids[${idForm}]`, String(option.product_additional_ingredient.id));
      idForm++;
    });
  }
  await changeCart(formData as any);

  productCount.value = newCount;
}

async function removeProductCount () {
  if (isLoading.value || checkErrors()) {
    return;
  }
	   await changeCart({
		   product_id: props.product.id,
		   count: 0
	   });
}

function checkErrors () {
  if (!additionalIngredients.value.length) {
    return false;
  }

  if (currentProductFromCart.value) {
    return false;
  }

  let hasError = false;

  const errorList: { [key: number]: string } = {};

  for (const item of additionalIngredients.value) {
    if (item.group.required === 1) {
      const hasEmptyListOption = props.selectedOptions && props.selectedOptions.some((option) =>
        option && option.group.title === item.group.title && option.list.length
      );

      if (!hasEmptyListOption) {
        errorList[item.group.id] = `Поле ${item.group.title} обязательно к заполнению 2`;
        hasError = true; // Устанавливаем флаг, что есть ошибка
      }
    }
  }

  return hasError; // Возвращаем значение флага, указывающего на наличие ошибок
}

watch(() => cartList.value, () => {
  if (!cartList.value.length || checkErrors()) {
    productCount.value = 0;
  }
}, { deep: true });

watch(() => props.selectedOptions, () => {
  hasErrors.value = checkErrors();

  if ((props.selectedOptions && props.selectedOptions.length === additionalIngredients.value.length)) {
    productCount.value = 0;
  }
}, { deep: true });

onMounted(() => {
  hasErrors.value = checkErrors();
});
</script>

<style>

</style>
